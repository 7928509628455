import React from "react"
import Layout from '../components/layout'
import LocationHelmet from "../components/LocationHelmet"
import PageTitle from "../components/PageTitle"
import { themeStyles, colors, presets, metrics } from '../utils/theme'

const StripAndWaxPage = () => {
    return (
    <Layout>
      <LocationHelmet
        title={"Professional Strip and Wax Floor Care Services | Blumont Services"}
        description={"Enhance your space with expert strip and wax floor care services. Trust Blumont Services for professional floor maintenance and restoration."}
        keywords={"Strip and wax floor care services, Strip and Wax Floor Care"}
        url={"https://www.blumontservices.com/strip-and-wax"}
      />
      <PageTitle fontSize={2} title={"Professional Strip and Wax Floor Care Services"}/>
      <div css={[themeStyles.contentWidth]}>
        <div css={themeStyles.textPadding}>
          <p>
            Enhance your space with expert strip and wax floor care services. Trust Blumont Services for professional floor maintenance and restoration.
          </p>
          <div style={{ marginBottom: '25vh' }}></div>
        </div>
      </div>
    </Layout>
    )
}

export default StripAndWaxPage;